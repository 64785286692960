import { useMemo } from 'react';

import { useAppSelector } from '../../../../app/hooks';
import {
  filteredMainFilterDataSelector,
  selectFormations,
  selectTeams,
} from '../../../../features';
import { useFilteredMetricsForTable } from '../../../../hooks';
import {
  IEntity,
  IFormationStatsTableData,
  IMetricWithDatasetMetric,
  IMetricWithValueRecord,
  ITeamRecord,
  ITeamStats,
  ITeamStatsRecord,
} from '../../../../types';
import { getMetricsWithValue } from '../../../../utils';

const getMetricsPercentilesWithValue = (
  metrics: IMetricWithDatasetMetric[],
  team: ITeamStats,
): IMetricWithValueRecord =>
  metrics.reduce<IMetricWithValueRecord>((acc, metric) => {
    if (team.teamOrder) {
      acc[metric.id] = {
        ...metric,
        value: team.teamOrder[metric.id],
      };
    }

    return acc;
  }, {});

const getTableData = (
  teamsStatsRecord: ITeamStatsRecord,
  teamsRecord: ITeamRecord,
  metrics: IMetricWithDatasetMetric[],
  filteredParts: string[],
) => {
  const tableData = Object.values(teamsStatsRecord)
    .reduce<IFormationStatsTableData[]>((acc, teamsWithStats) => {
      const team = teamsRecord[teamsWithStats.teamId];
      if (!team.competitionIds.some(id => filteredParts.includes(id))) {
        return acc;
      }

      const teamStats = teamsStatsRecord[team.id];
      const metricsPercentilesWithValue = getMetricsPercentilesWithValue(metrics, teamStats);
      const metricsWithValue = getMetricsWithValue(metrics, teamsWithStats.stats);
      const teamOrder = teamsStatsRecord[team.id].teamOrder;

      if (team) {
        acc.push({
          entityId: team.id,
          teamId: team.id,
          teamName: team.name,
          gp: teamsWithStats.stats.gp,
          toi: teamsWithStats.stats.toi,
          stats: metricsWithValue,
          percentiles: metricsPercentilesWithValue,
          summaryPercentile: teamOrder ? teamOrder['summaryIndex'] : undefined,
        });
      }

      return acc;
    }, [])
    .sort((a, b) => b.toi - a.toi);

  return tableData;
};

export const useDataForTable = () => {
  const formations = useAppSelector(selectFormations);
  const teams = useAppSelector(selectTeams);
  const { filteredParts } = useAppSelector(filteredMainFilterDataSelector);

  const getFilteredMetrics = useFilteredMetricsForTable();

  const data: IFormationStatsTableData[] = useMemo(() => {
    const filteredMetrics = getFilteredMetrics(IEntity.teams);

    if (filteredMetrics) {
      return getTableData(
        formations.byId,
        teams.byId,
        filteredMetrics,
        filteredParts.map(part => part.id),
      );
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formations.byId, teams.byId, getFilteredMetrics]);

  return data;
};
