import { ITranslationKeys } from '../../i18n/types';
import { IAnalyticsType, ISelectOption, ITrackingUserType } from '../../types';
import { allFemaleOption } from './commonOptions.constants';

export const analyticsTypeDefaultOption: ISelectOption = {
  value: IAnalyticsType.accessCount,
  label: ITranslationKeys.visits,
};

export const analyticsTypeScreenDefaultOption: ISelectOption = {
  value: IAnalyticsType.screenCount,
  label: ITranslationKeys.openScreensCount,
};

export const trackingRoleOptions: ISelectOption[] = [
  allFemaleOption,
  { value: ITrackingUserType.team, label: ITranslationKeys.teams },
  { value: ITrackingUserType.media, label: ITranslationKeys.media },
  { value: ITrackingUserType.other, label: ITranslationKeys.others },
];

export const analyticsTypeOptions: ISelectOption[] = [
  analyticsTypeDefaultOption,
  { value: IAnalyticsType.uniqueAccessCount, label: ITranslationKeys.uniqueUsers },
  analyticsTypeScreenDefaultOption,
];

export const analyticsTypeScreenOptions: ISelectOption[] = [
  analyticsTypeScreenDefaultOption,
  { value: 'uniqueScreenCount', label: ITranslationKeys.uniqueUsers },
];

export const mediaOptions: ISelectOption[] = [
  { value: 'O2TV', label: 'Oneplay Sport', additionalValue: 'media' },
  { value: 'iSport', label: 'iSport.cz', additionalValue: 'media' },
  { value: 'CT', label: 'ČT sport', additionalValue: 'media' },
  { value: 'sportCz', label: 'Sport.cz', additionalValue: 'media' },
  { value: 'hokejCz', label: 'hokej.cz', additionalValue: 'media' },
  { value: 'seznamZpravy', label: 'Seznam Zprávy', additionalValue: 'media' },
];

export const eventLogEventOptions: ISelectOption[] = [
  {
    value: 'all',
    label: ITranslationKeys.all,
    labelTranslationsOptions: { context: 'female' },
    isAllOption: true,
  },
  // HL content
  { value: ITranslationKeys.players, label: ITranslationKeys.players },
  { value: ITranslationKeys.formations, label: ITranslationKeys.formations },
  { value: ITranslationKeys.videomaps, label: ITranslationKeys.videomaps },
  { value: ITranslationKeys.goalkeepers, label: ITranslationKeys.goalkeepers },
  { value: ITranslationKeys.games, label: ITranslationKeys.games },
  { value: ITranslationKeys.teams, label: ITranslationKeys.teams },
  // EC content
  { value: ITranslationKeys.overview, label: ITranslationKeys.overview },
  { value: ITranslationKeys.shots, label: ITranslationKeys.shots },
  { value: ITranslationKeys.formationsCompare, label: ITranslationKeys.formationsCompare },
  { value: ITranslationKeys.faceoffs, label: ITranslationKeys.faceoffs },
  { value: ITranslationKeys.iceTime, label: ITranslationKeys.iceTime },
  { value: ITranslationKeys.headToHead, label: ITranslationKeys.headToHead },
  { value: ITranslationKeys.momentum, label: ITranslationKeys.momentum },
];

export const attendanceDefaultOption: ISelectOption = {
  value: 'all',
  label: ITranslationKeys.doesNotMatter,
  isAllOption: true,
};

export const attendanceOptions: ISelectOption[] = [
  attendanceDefaultOption,
  {
    value: '33',
    label: ITranslationKeys.higherThan,
    labelTranslationsOptions: { context: '33 %' },
  },
  {
    value: '50',
    label: ITranslationKeys.higherThan,
    labelTranslationsOptions: { context: '50 %' },
  },
  {
    value: '66',
    label: ITranslationKeys.higherThan,
    labelTranslationsOptions: { context: '66 %' },
  },
  {
    value: '95',
    label: ITranslationKeys.higherThan,
    labelTranslationsOptions: { context: '95 %' },
  },
  {
    value: '100',
    label: ITranslationKeys.equalTo,
    labelTranslationsOptions: { context: '100 %' },
  },
  { value: '0', label: ITranslationKeys.equalTo, labelTranslationsOptions: { context: '0 %' } },
];
