import { AxiosError } from 'axios';
import { RefObject } from 'react';
import { toast } from 'react-toastify';

import { BASE_TEMPLATE_NAME, DEFAULT_CONTENT_TEMPLATE_NAMES, IS_USER_DATASET } from '../constants';
import i18n from '../i18n/i18n';
import { ITranslationKeys } from '../i18n/types';
import {
  ICoordinates,
  IDatasetMetric,
  IDefinedDatasets,
  IEntity,
  IGameRecord,
  IGoalkeeper,
  ILanguageType,
  ILeftRight,
  ILineupRecord,
  IMetricWithDatasetMetric,
  IMetricWithRankValues,
  IMetricWithRankValuesRecord,
  IMetricWithValueRecord,
  IModifiers,
  IOnOffColors,
  IPlayer,
  IPlayerColors,
  IRankRecord,
  IScoreState,
  ISelectOption,
  IShiftVideoRecord,
  ISimilarPlayerNameRecord,
  IStats,
  IStatsWithPercentiles,
  ITeam,
  IUserDatasetRecord,
  IVideoRecord,
  IWowyType,
} from '../types';

export const camelCaseToKebabCase = (str: string): string =>
  str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

export const transformObjectKeysToKebabCase = (objectToTransform: IModifiers): IModifiers => {
  const transformedObject: IModifiers = {};

  for (const [key, value] of Object.entries(objectToTransform)) {
    const transformedKey = camelCaseToKebabCase(key);
    transformedObject[transformedKey] = value;
  }

  return transformedObject;
};

export const removeDiacritics = (str: string) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const replaceWhitespaces = (str: string, replaceValue: string) =>
  str.replace(/\s/g, replaceValue);

export const defaultCatchErrorCallback = (error: AxiosError) => {
  const errorMessage = error.message ?? ITranslationKeys.defaultErrorMessage;
  throw new Error(errorMessage);
};

/**
 * Returns a class that represents the color of the bar by percent value.
 */
export const getColorByPercent = (value: number) => {
  if (value === 0) return 'color0';
  if (value < 12.5) return 'color1';
  if (value < 25) return 'color2';
  if (value < 37.5) return 'color3';
  if (value < 50) return 'color4';
  if (value < 62.5) return 'color5';
  if (value < 75) return 'color6';
  if (value < 87.5) return 'color7';
  return 'color8';
};

/**
 * Returns a class that represents the color of the cell by percent value.
 */
export const getCellColorByPercent = (value: number) => {
  if (value >= 0 && value < 10) return 'cell0';
  if (value < 20) return 'cell1';
  if (value < 30) return 'cell2';
  if (value < 40) return 'cell3';
  if (value < 50) return 'cell4';
  if (value < 60) return 'cell5';
  if (value < 70) return 'cell6';
  if (value < 80) return 'cell7';
  if (value < 90) return 'cell8';
  if (value <= 100) return 'cell9';
  return 'cell0';
};

/**
 * Returns a class that represents the color of the cell by percent value.
 */
export const getCellColorByValue = (value: number) => {
  if (value === 15) return 'cell0';
  if (value === 14) return 'cell1';
  if (value === 13) return 'cell2';
  if (value === 12 || value === 11) return 'cell3';
  if (value === 10 || value === 9) return 'cell4';
  if (value === 6 || value === 7 || value === 8) return 'cell5';
  if (value === 4 || value === 5) return 'cell6';
  if (value === 3) return 'cell7';
  if (value === 2) return 'cell8';
  if (value === 1) return 'cell9';
};

export const getPlayerFullName = (player: IPlayer | IGoalkeeper): string =>
  player.name + ' ' + player.surname;

export const getPlayerShortName = (
  player: IPlayer | IGoalkeeper,
  similarPlayerNames?: ISimilarPlayerNameRecord,
): string => {
  if (similarPlayerNames) {
    const similarPlayer = similarPlayerNames[player.id];
    if (similarPlayer) return similarPlayer;
  }

  return `${player?.surname} ${player?.name.substring(0, 1)}.`;
};

export const convertPlayerFullNameToShortName = (name: string): string => {
  const allNames = name.split(' ');
  return `${allNames[0]} ${allNames[1].substring(0, 1)}.`;
};

export const handleScroll = (
  elementRef: RefObject<HTMLDivElement> | undefined,
  direction: ILeftRight,
  scrollSizePx: number = 100,
) => {
  if (elementRef && elementRef.current) {
    if (direction === 'right') {
      elementRef.current.scrollLeft += scrollSizePx;
    } else {
      elementRef.current.scrollLeft -= scrollSizePx;
    }
  }
};

export const getScoreStateShortcut = (scoreState: IScoreState) => {
  if (scoreState === IScoreState.overtime) return ITranslationKeys.overtimeShortcut;
  if (scoreState === IScoreState.shooting) return ITranslationKeys.shootingShortcut;
  return undefined;
};

export const getDataTemplateValue = (
  dataTemplate: ISelectOption,
  defaultTemplateName: string = BASE_TEMPLATE_NAME,
) => (dataTemplate.value === 'default' ? defaultTemplateName : dataTemplate.value);

export const getMetricsWithValue = (
  metrics: IMetricWithDatasetMetric[],
  stats: IStats,
): IMetricWithValueRecord =>
  metrics.reduce<IMetricWithValueRecord>((acc, metric) => {
    acc[metric.id] = {
      ...metric,
      value: stats[metric.id],
    };

    return acc;
  }, {});

export const getMetricsPercentilesWithValue = (
  metrics: IMetricWithDatasetMetric[],
  statsWithPercentiles: IStatsWithPercentiles,
): IMetricWithValueRecord => {
  if (!statsWithPercentiles.percentiles) return {};
  const percentiles: IStats = statsWithPercentiles.percentiles;

  return metrics.reduce<IMetricWithValueRecord>((acc, metric) => {
    acc[metric.id] = {
      ...metric,
      value: percentiles[metric.id as keyof typeof statsWithPercentiles.percentiles],
    };

    return acc;
  }, {});
};

export const getMetricsWithRankValues = (
  metrics: IMetricWithDatasetMetric[],
  rank: IRankRecord | undefined,
  playerId: string,
  teamId: string,
): IMetricWithRankValuesRecord => {
  if (!rank) return {};

  return metrics.reduce<IMetricWithRankValuesRecord>((acc, metric) => {
    const rankEntry = rank[metric.id];
    if (!rankEntry?.rankStats) return acc;

    const rankStats = rankEntry.rankStats;
    const overallRankStat = rankStats.find(player => player.playerId === playerId);
    const teamEntities = rankStats.filter(player => player.teamId === teamId);
    const teamRankStat = teamEntities.find(player => player.playerId === playerId);
    if (!overallRankStat || !teamRankStat) return acc;

    const overallPosition = rankStats.indexOf(overallRankStat) + 1;
    const teamPosition = teamEntities.indexOf(teamRankStat) + 1;
    const playerRankStat: IMetricWithRankValues = {
      ...metric,
      overallRank: {
        rank: overallPosition,
        total: rankStats.length,
      },
      teamRank: {
        rank: teamPosition,
        total: teamEntities.length,
      },
    };
    acc[metric.id] = playerRankStat;

    return acc;
  }, {});
};

export const mapPlayerLineups = (lineupRecord: ILineupRecord): string =>
  Object.values(lineupRecord)
    .map(player => player.line + player.position?.toString())
    .join('-');

export const getPlayerStick = (stick: string) =>
  stick === 'left' ? ITranslationKeys.leftStick : ITranslationKeys.rightStick;

export const getPlayerPositionShortcut = (position: string) => {
  if (position === 'DE') return ITranslationKeys.defenderShortcut;
  if (position === 'FO') return ITranslationKeys.attackerShortcut;
  return ITranslationKeys.goalkeeperShortcut;
};
/**
 * Takes boolean value and returns string param name for path, otherwise undefined
 * @param value Boolean or undefined value to be resolved
 * @param returnValue String value to be returned if boolean value is true
 * @returns String param name for path or undefined
 */
export const urlBooleanParamResolver = (value: boolean | undefined, returnValue: string) =>
  value ? returnValue : undefined;

/**
 * Builds correct path for request with dynamic path parameters. Final path depends on params order.
 * @param base Base url path
 * @param params Dynamic path parameters
 * @returns Request path
 */
export const buildPath = (base: string, params?: Array<string | undefined>) => {
  if (!params) return base;

  const validParams = params.filter(param => param);
  const path = [base, ...validParams].join('/');
  return path;
};

export const getGamesOptions = (byId: IGameRecord): ISelectOption[] =>
  Object.values(byId)
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    .map(game => ({
      value: game.id,
      label: game.id,
    }));

export const createShiftVideoUuid = (playerId: string, videoTime: number): string =>
  `${playerId}_${videoTime}`;

export const getVideoOptions = (byId: IVideoRecord): ISelectOption[] =>
  Object.values(byId).map(({ videoUuid, videoId }) => ({
    value: videoUuid,
    label: videoId,
  }));

export const getShiftVideoOptions = (videoRecord: IShiftVideoRecord): ISelectOption[] =>
  Object.values(videoRecord)
    .filter(video => !!video.shiftInfo)
    .map(({ playerId, videoTime }) => {
      const videoUuid = createShiftVideoUuid(playerId, videoTime);

      return {
        value: videoUuid,
        label: videoUuid,
      };
    });

export const getValueOrUndefined = <T>(value: T | null): T | undefined =>
  value === null ? undefined : value;

export const getDefaultPredefinedOptionByValue = <TOption = undefined>(
  options: ISelectOption<TOption>[],
  defaultValue: string = 'all',
): ISelectOption<TOption> => options.find(option => option.value === defaultValue) || options[0];

export const changeRequestFailed = (name: string, nameId: string, error: AxiosError) => {
  toast.error(name, {
    toastId: nameId,
  });
  console.error(error);
};

export const filterObjectKeysByText = <T extends {}>(
  object: Record<string, T>,
  includesText: string,
) =>
  Object.keys(object)
    .filter(key => key.includes(includesText))
    .reduce<Record<string, T>>((acc, key) => {
      acc[key] = object[key];
      return acc;
    }, {});

export const getMetricsFromDataTemplate = (
  definedDatasets: IDefinedDatasets,
  userDatasetRecord: IUserDatasetRecord,
  dataTemplate: ISelectOption | undefined,
  defaultTemplateName: string = BASE_TEMPLATE_NAME,
  entity: IEntity = IEntity.players,
): IDatasetMetric[] => {
  if (dataTemplate) {
    if (dataTemplate.additionalValue === IS_USER_DATASET) {
      const dataset = userDatasetRecord[dataTemplate.value];
      if (!dataset) {
        console.error('[common.utils]: No dataset found');
        return [];
      }

      return Object.values(dataset.datasetMetrics);
    }

    const dataTemplateValue = getDataTemplateValue(dataTemplate, defaultTemplateName);
    return Object.values(definedDatasets[entity].byId[dataTemplateValue].datasetMetrics);
  }

  return [];
};

export const getDataTemplateMetrics = (
  definedDatasets: IDefinedDatasets,
  userDatasetRecord: IUserDatasetRecord,
  dataTemplate: ISelectOption | undefined,
  activeTab: string | undefined,
  entity: IEntity = IEntity.players,
): IDatasetMetric[] => {
  const contentTemplateName = activeTab ? DEFAULT_CONTENT_TEMPLATE_NAMES[activeTab] : undefined;
  const defaultTemplateName = contentTemplateName ?? BASE_TEMPLATE_NAME;

  return getMetricsFromDataTemplate(
    definedDatasets,
    userDatasetRecord,
    dataTemplate,
    defaultTemplateName,
    entity,
  );
};

export const teamsToOptions = (
  filteredTeams: ITeam[],
  attr: 'name' | 'shortName',
): ISelectOption[] => {
  return [
    ...Object.values(filteredTeams)
      .map(team => {
        return {
          value: team.id,
          label: team[attr],
        };
      })
      .sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        return labelA < labelB ? -1 : labelA > labelB ? 1 : 0;
      }),
  ];
};

export const getStickerColorByWowyType = (type?: IWowyType) => {
  if (type === IWowyType.separately) return 'red';
  if (type === IWowyType.teammateSeparately) return 'violet';
  return 'green';
};

export const getOnOffColor = (
  color: IPlayerColors | IOnOffColors | undefined,
  onOffColoredCondition: boolean,
  isOn: boolean | undefined,
) => {
  if (onOffColoredCondition) {
    return isOn ? IOnOffColors.on : IOnOffColors.off;
  }

  return color;
};

/**
 * Finds chart's maximum value for given axis.
 * @param data Data to be searched.
 * @param axis Axis x or y.
 * @returns Maximum value for given axis.
 */
export const findChartMaxValue = (data: Partial<ICoordinates>[], axis: 'x' | 'y' = 'x') => {
  const axisValues = data.map(item => item[axis]).filter((item): item is number => !!item);

  return Math.max(...axisValues);
};

export const moveArrayItem = <T>(array: T[], from: number, to: number) => {
  const [item] = array.splice(from, 1);
  array.splice(to, 0, item);
};

export const removeArrayItem = <T>(array: T[], index: number) => {
  const arrCopy = [...array];
  if (index >= 0 && index < arrCopy.length) {
    arrCopy.splice(index, 1);
  }

  return arrCopy;
};

export const filterUniqueGameIds = <T extends { matchId: string }>(array: T[]) =>
  array.map(item => item.matchId).filter((value, index, self) => self.indexOf(value) === index);

export const transformStringToLanguageType = (str: string): ILanguageType<string> =>
  (i18n.languages as (keyof ILanguageType<string>)[]).reduce((acc, lang) => {
    acc[lang] = str;
    return acc;
  }, {} as ILanguageType<string>);

export const slugify = (str: string) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
  str = str.toLowerCase(); // convert string to lowercase
  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
  return str;
};
